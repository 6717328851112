import { generateId } from "components/PWATabs/trackingTab/TrackingTab";
import { t } from "i18next";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppStore } from "store/appStore";
import useIdStore from "store/pwaId";
import useSetupStore from "store/setupSettingsStore";
import { debounce } from "utils/debounce";
import { getToken } from "utils/getToken";
import useFetchFormData from "./useFetchFormData";
import useFetch from "./useFetch";
import useCautionStore from "store/cautionStore";

function usePwaSave() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { appData, saveAppData, clearAppData } = useAppStore();
  const setSettingProcess = useSetupStore((state) => state.setSettingProcess);
  const resetSteps = useSetupStore((state) => state.resetAllSteps);
  const { closeModal, pagePath, handlePathChange } = useCautionStore();
  const { id: paramsID } = useParams();
  const { id = paramsID, setId, setLastId, clearId } = useIdStore();
  const navigate = useNavigate();
  const session = getToken();

  const { triggerFetch: updatePWA } = useFetchFormData({
    url: `pwa/${paramsID}`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${session}`,
    },
  });

  const { triggerFetch: createPwa } = useFetchFormData({
    url: "pwa",
    method: "POST",
    headers: {
      Authorization: `Bearer ${session}`,
    },
  });

  const { triggerFetch: attachDomain } = useFetch({
    url: "",
    method: "POST",
    headers: {
      Authorization: `Bearer ${session}`,
    },
  });

  const { triggerFetch: getDomains } = useFetch({
    url: "tracking/keitaro-proxy",
    method: "POST",
    headers: {
      Authorization: `Bearer ${session}`,
    },
    body: {
      method: "GET",
      uri: "/domains",
    },
  });

  const { triggerFetch: createCampaign } = useFetch({
    url: "tracking/keitaro-proxy",
    method: "POST",
    headers: {
      Authorization: `Bearer ${session}`,
      "Content-Type": "application/json",
    },
    body: {
      method: "POST",
      uri: "/campaigns",
      requestBody: {},
    },
  });

  const { triggerFetch: updateCampaign } = useFetch({
    url: "tracking/keitaro-proxy",
    method: "POST",
    headers: {
      Authorization: `Bearer ${session}`,
      "Content-Type": "application/json",
    },
  });

  const { triggerFetch: createFlow } = useFetch({
    url: "tracking/keitaro-proxy",
    method: "POST",
    headers: {
      Authorization: `Bearer ${session}`,
      "Content-Type": "application/json",
    },
    body: {
      method: "POST",
      uri: "/streams",
    },
  });

  const { triggerFetch: updateFlow } = useFetch({
    url: "tracking/keitaro-proxy",
    method: "POST",
    headers: {
      Authorization: `Bearer ${session}`,
      "Content-Type": "application/json",
    },
  });

  async function changePwaDomain(domainID: string, pwaid: string) {
    try {
      const result = await attachDomain(
        {
          domain: domainID,
        },
        `pwa/${pwaid}/domain`
      );

      if (!result.success) {
        toast.error(result?.message || "Attach domain failed");
        return;
      }
      toast.success("Domain attached successfully");
      return result;
    } catch (error: any) {
      toast.error("Error", error?.message || "Attach domain failed");
    }
  }

  const postCampaign = async (domainId: number, pwaID: string, context?: any) => {
    const isUpdate = context?.campaignId;
    const campaignRequest = isUpdate ? updateCampaign : createCampaign;
    const isFacebookToken = appData.fbtoken;
    const isFacebookPixel = appData.fbpixel;
    const isDomain = context.domainName;
    const userData = localStorage.getItem("userData") as string;
    const userObject = JSON.parse(userData);
    const userId = userObject.userId;
    const cookies_ttl = !appData.cookiesTtl || Number(appData.cookiesTtl) === 0 ? 24 : appData.cookiesTtl;

    const facebookPixelPostback =
      (isFacebookPixel &&
        isFacebookPixel &&
        isDomain && {
          postbacks: [
            {
              campaign_id: context?.campaign_id,
              method: "POST",
              statuses: ["sale"],
              url: `https://graph.facebook.com/v10.0/${isFacebookPixel}/events?access_token=${isFacebookToken}&data=[{"action_source":"website","event_name":"Purchase","event_time":{date:U},"custom_data":{"currency":"USD","value":{revenue}},"event_source_url":"${isDomain}","user_data":{"external_id": "{subid}","client_ip_address":"{_ip}","client_user_agent":"{_user_agent}","fbc":"fb.1.{date:U}.{fbclid}","fbp":"fb.1.{date:U}.{random:1,9999}"}}]`,
            },
            {
              campaign_id: context?.campaign_id,
              method: "POST",
              statuses: ["lead"],
              url: `https://graph.facebook.com/v10.0/${isFacebookPixel}/events?access_token=${isFacebookToken}&data=[{"action_source":"website","event_name":"CompleteRegistration","event_time":{date:U},"event_source_url":"${isDomain}","user_data":{"external_id": "{subid}","client_ip_address":"{_ip}","client_user_agent":"{_user_agent}","fbc":"fb.1.{date:U}.{fbclid}","fbp":"fb.1.{date:U}.{random:1,9999}"}}]`,
            },
          ],
        }) ||
      {};

    const campaignData = isUpdate
      ? {
          method: "PUT",
          uri: `/campaigns/${context?.campaignId}`,
          requestBody: {
            ...context.requestBody,
            cookies_ttl,
            parameters: { external_id: { name: "fbclid", placeholder: "", alias: "" } },
            ...facebookPixelPostback,
          },
        }
      : {
          requestBody: {
            alias: generateId(),
            name: `${userId} ${appData.appName}`,
            domain_id: domainId,
            cookies_ttl,
            parameters: { external_id: { name: "fbclid", placeholder: "", alias: "" } },
            ...facebookPixelPostback,
          },
        };
    try {
      const campaignRes = await campaignRequest(campaignData);
      const campaignId = campaignRes.data.id;

      if (campaignId) {
        const flowID = context?.flowId;
        const flowRequest = flowID ? updateFlow : createFlow;
        const flowData = flowID
          ? {
              method: "PUT",
              uri: `/streams/${flowID}`,
              requestBody: {
                action_payload: appData.offerLink,
              },
            }
          : {
              method: "POST",
              uri: "/streams",
              requestBody: {
                type: "regular",
                name: "Black",
                position: 1,
                weight: 100,
                state: "active",
                action_type: "http",
                schema: "redirect",
                collect_clicks: true,
                filter_or: false,
                action_payload: appData.offerLink,
                campaign_id: campaignId,
              },
            };

        const flowRes = await flowRequest(flowData);

        const response = await fetch(`${process.env.REACT_APP_API_URL}pwa/${pwaID}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${session}`,
          },
          body: JSON.stringify({
            blackLink: `${campaignRes.data.domain}${campaignRes.data.alias}`,
            flowId: flowRes.data.id,
            campaignId: campaignId,
          }),
        });
        if (!response.ok) {
          throw new Error(`Ошибка: ${response.status} ${response.statusText}`);
        }
      }
    } catch (error) {
      console.error("Error creating campaign:", error);
    }
  };

  const handlePwa = debounce(async () => {
    const data = new FormData();
    const pathForNavigate = pagePath ? pagePath : "/pwa";

    const appendField = (field: string, value: any) => {
      if (field === "cookiesTtl") {
        const cookiesTtlValue = Number(value) || 0;
        const validValue = cookiesTtlValue > 0 ? cookiesTtlValue : 24;
        data.append(field, validValue.toString());
      } else if (Array.isArray(value) || typeof value === "object") {
        data.append(field, JSON.stringify(value));
      } else {
        data.append(field, value as string | Blob);
      }
    };

    const removeTabValue = () => {
      if (pathForNavigate === "/pwa") {
        return;
      } else {
        localStorage.removeItem("lastTabValue");
      }
    };

    if (!id) {
      setIsLoading(true);
      Object.entries(appData).forEach(([field, value]) => {
        if (field === "creatives") {
          const creatives = value as typeof appData.creatives;
          if (creatives?.icon) {
            data.append("icon", creatives.icon);
          }
          if (creatives?.previews?.length) {
            creatives.previews.forEach((preview: any) => {
              data.append("previews", preview);
            });
          }
        } else {
          appendField(field, value);
        }
      });

      let res = await createPwa(data);
      if (res?.data) {
        saveAppData(res.data);
        setId(res.data._id);

        const storageDomain = localStorage.getItem("tmpDomainID");
        if (storageDomain) {
          res = await changePwaDomain(storageDomain, res.data._id);
          localStorage.removeItem("tmpDomainID");
        }
        if (res?.data?.domain?.name) {
          const domainsResponse = await getDomains();
          const domainsData = domainsResponse.data;
          const findDomainInKeitaro = domainsData.find((domain: any) => domain.name.includes(res.data.domain.name));
          if (findDomainInKeitaro?.id) {
            await postCampaign(findDomainInKeitaro.id, res.data._id, {
              domainName: res.data.domain.name,
            });
          }
        }

        clearAppData();
        setSettingProcess(false);
        resetSteps();
        setLastId(res.data._id);
        closeModal();
        toast.success(t("successPwaCreate"));
      }
      setTimeout(() => {
        setIsLoading(false);
        navigate(`${pathForNavigate}`);
        removeTabValue();
        handlePathChange(null);
      }, 1000);
    } else {
      setIsLoading(true);

      if (id) {
        Object.entries(appData).forEach(([field, value]) => {
          if (field === "creatives") {
            const creatives = value as typeof appData.creatives;
            if (creatives?.icon) {
              data.append("icon", creatives.icon);
            }
            if (creatives?.previews?.length) {
              creatives.previews.forEach((preview: any) => {
                data.append("previews", preview);
              });
            }
          } else {
            appendField(field, value);
          }
        });
        const res = await updatePWA(data);
        if (res?.data?.domain?.name) {
          const domainsResponse = await getDomains();
          const domainsData = domainsResponse.data;
          const findDomainInKeitaro = domainsData.find((domain: any) => domain.name.includes(res.data.domain.name));
          if (findDomainInKeitaro?.id) {
            await postCampaign(findDomainInKeitaro.id, res.data._id, {
              campaignId: res.data.campaignId,
              flowId: appData.flowId,
              requestBody: { name: appData.appName, domain_id: findDomainInKeitaro.id },
              domainName: res.data.domain.name,
            });
          }
        }

        if (res?.data) {
          saveAppData(res.data);
          clearAppData();
          setSettingProcess(false);
          resetSteps();
          setId(id);
          setLastId(id);
          closeModal();
          toast.success(t("successPwaUpdate"));
        }
      } else {
        clearAppData();
        setSettingProcess(false);
        resetSteps();
        clearId();
      }
      setTimeout(() => {
        setIsLoading(false);
        navigate(`${pathForNavigate}`);
        removeTabValue();
        handlePathChange(null);
      }, 300);
    }
  }, 1000);

  return { isLoading, handlePwa };
}

export default usePwaSave;
