import { Box, Typography } from "@mui/material";
import { ICustomGroupingFiledProps } from "interfaces/stats";
import showPreviewIcon from "utils/showPreviewImage";
import ImageIcon from "assets/icons/ImageIcon";
import { formatDateByLocale } from "utils/formateDate";

const CustomGroupingField = ({ appName, icon, day }: ICustomGroupingFiledProps) => {
  const formattedDate = formatDateByLocale(day);
  return day ? (
    <Box
      sx={{
        fontSize: "16px",
        fontWeight: "400",
        fontFamily: "var(--cygreRegular), sans-serif",
      }}
    >
      {formattedDate}
    </Box>
  ) : (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        gap: "10px",
      }}
    >
      {icon ? (
        <img
          src={showPreviewIcon(icon)}
          alt={`${appName} icon`}
          style={{ width: "30px", height: "30px", borderRadius: "50%" }}
        />
      ) : (
        <Box
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <ImageIcon />
        </Box>
      )}

      <Typography
        sx={{
          fontFamily: "var(--cygreMedium), sans-serif",
          fontSize: "16px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {appName}
      </Typography>
    </Box>
  );
};

export default CustomGroupingField;
