import { Box, Button, Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { MenuOptions } from "interfaces/stats";
import { useEffect, useState } from "react";

interface IFilterMenuListProps {
  menuOptions: MenuOptions[];
  rowsToShow: (string | number)[];
  handleFilterBtnClick: (rowsArr: (string | number)[]) => void;
}

export const FilterMunuList = ({ menuOptions, rowsToShow, handleFilterBtnClick }: IFilterMenuListProps) => {
  const [checkboxStatus, setCheckboxStatus] = useState<Array<string | number>>([]);
  const allCheckboxValues = menuOptions
    .map((option) => option.day ?? option.campaignId)
    .filter((value): value is string | number => value !== undefined);

  const handleSetCheckboxStatus = (value: string | number) => {
    const currentCheckeIndex = checkboxStatus.indexOf(value);
    const newChecked = [...checkboxStatus];

    if (currentCheckeIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentCheckeIndex, 1);
    }

    setCheckboxStatus(newChecked);
  };

  const handleResetBtnClick = () => {
    setCheckboxStatus(allCheckboxValues);
  };

  useEffect(() => {
    if (!rowsToShow || rowsToShow.length === 0) {
      return;
    }

    setCheckboxStatus(rowsToShow);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
        {menuOptions.map((option) => {
          const listItemBtnId = option.day ?? option.campaignId ?? "";
          const isChecked = checkboxStatus.includes(listItemBtnId);
          const itemKey = `${option.campaignId ?? ""}${option.day ?? ""}`;

          return (
            <ListItem key={itemKey} disablePadding>
              <ListItemButton
                id={listItemBtnId.toString()}
                role={undefined}
                onClick={() => handleSetCheckboxStatus(listItemBtnId)}
                dense
              >
                <ListItemText primary={option.day || option.appName} />
                <ListItemIcon sx={{ minWidth: 0 }}>
                  <Checkbox
                    sx={{ justifyContent: "flex-end" }}
                    edge="end"
                    checked={isChecked}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>

      <Box sx={{ padding: "8px 16px 16px 16px", display: "flex", justifyContent: "space-between" }}>
        <Button
          sx={{
            padding: "0px",
            backgroundColor: "transparent",
            boxShadow: "none",
            justifyContent: "flex-start",
            "&:hover": {
              color: "#F0670B",
              backgroundColor: "transparent",
              boxShadow: "none",
            },
            color: "#F4701B",
          }}
          className="filter_rows_btn"
          variant="contained"
          aria-label="generate report"
          size="medium"
          onClick={() => handleResetBtnClick()}
        >
          Reset
        </Button>
        <Button
          sx={{
            padding: "0px",
            backgroundColor: "#F4701B",
            textTransform: "uppercase",
            "&:hover": {
              backgroundColor: "#F0670B",
            },
            color: "white",
          }}
          className="filter_rows_btn"
          variant="contained"
          aria-label="generate report"
          size="medium"
          onClick={() => handleFilterBtnClick(checkboxStatus)}
        >
          Ok
        </Button>
      </Box>
    </>
  );
};
