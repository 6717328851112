import { Box, Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IPwaComponent } from "interfaces/pwas";
import Preloader from "components/GlobalPreloader/Preloader";
import { IPushSubscription, IPwaStatistics, ITransaction } from "interfaces/stats";
import StatsCard from "components/StatsCard";
import { useState } from "react";
import CustomCheckbox from "components/CustomCheckbox";
import ResizableStatsTable from "components/ResizableStatsTable";
import { checkDayStatisticsExists } from "utils/statsHelpers";

const StatsTable = ({
  loading,
  pwas,
  allPwasStats,
  storeStats,
  installs,
  view,
  pushSubscribes,
  report,
  needNewReport,
  buildReport,
}: IPwaComponent) => {
  const [showDaysStats, setShowDaysStats] = useState<boolean>(false);
  const { t } = useTranslation();

  const selectedPwaId = pwas?.filter((pwa) => {
    return pwa.campaignId === report;
  })[0]?._id;

  const filtredAllPwasStats = allPwasStats.filter((pwaStats: any) => {
    if (report && report !== "empty") {
      return pwaStats.campaign_id === report;
    }
    return pwaStats;
  });

  const selectedPwaStatistics: Record<string, IPwaStatistics> = filtredAllPwasStats?.reduce((acc: any, click: any) => {
    const { day, campaign_unique_clicks, cpc, clicks, leads, sales, campaign, campaign_id } = click;

    if (!acc[day]) {
      acc[day] = {
        statsType: "selected",
        campaign,
        campaign_id,
        clicks: 0,
        campaign_unique_clicks: 0,
        cpc: 0,
        installs: 0,
        push_subs: 0,
        deposits: 0,
        regs: 0,
        storeStats: { clicks: 0, campaign_unique_clicks: 0 },
      };
    }

    acc[day].clicks += clicks;
    acc[day].campaign_unique_clicks += campaign_unique_clicks;
    acc[day].cpc += cpc;
    acc[day].regs += leads;
    acc[day].deposits += sales;

    return acc;
  }, {});

  const mergedStoreStatsByDay = storeStats
    ?.filter((stats: any) => {
      return stats.keyword === selectedPwaId;
    })
    .reduce((acc: any, click: any) => {
      const { day, campaign_unique_clicks, clicks } = click;
      if (!acc[day]) {
        acc[day] = {
          clicks: 0,
          campaign_unique_clicks: 0,
        };
      }
      acc[day].clicks += clicks;
      acc[day].campaign_unique_clicks += campaign_unique_clicks;
      return acc;
    }, {});

  Object.entries(installs || {}).forEach(([campaignId, transactions]) => {
    if (Number(campaignId) !== Number(report)) {
      return;
    }

    transactions?.forEach((transaction: ITransaction) => {
      const transactionDay = new Date(transaction.createdAt).toISOString().split("T")[0];
      checkDayStatisticsExists(selectedPwaStatistics, transactionDay, Number(campaignId));

      Object.entries(selectedPwaStatistics).forEach(([day, group]: [string, any]) => {
        if (Number(group.campaign_id) === Number(campaignId) && day === transactionDay) {
          group.installs += 1;
        }
      });
    });
  });

  Object.entries(pushSubscribes || {})?.forEach(([campaignId, subscriptions]) => {
    if (Number(campaignId) !== Number(report)) {
      return;
    }
    subscriptions?.forEach((subscription: IPushSubscription) => {
      const pushSubscriptionDay = new Date(subscription.created_at).toISOString().split("T")[0];
      checkDayStatisticsExists(selectedPwaStatistics, pushSubscriptionDay, Number(campaignId));

      Object.entries(selectedPwaStatistics).forEach(([day, group]: [string, any]) => {
        if (Number(group.campaign_id) === Number(campaignId) && day === pushSubscriptionDay) {
          group.push_subs += 1;
        }
      });
    });
  });

  Object.entries(mergedStoreStatsByDay || {})?.forEach(([storeStatsDay, storeStatsItem]) => {
    checkDayStatisticsExists(selectedPwaStatistics, storeStatsDay, Number(report));

    Object.entries(selectedPwaStatistics).forEach(([day, group]: [string, any]) => {
      if (Number(group.campaign_id) === Number(report) && day === storeStatsDay) {
        group.storeStats = storeStatsItem;
      }
    });
  });

  const selectedPwaStatisticsArray = Object.entries(selectedPwaStatistics || {})
    .sort(([dayA], [dayB]) => dayB.localeCompare(dayA))
    .map(([day, data]) => ({
      day,
      ...data,
    }));

  const allPwasStatistics = pwas
    ? pwas
        .filter((pwa) => !report || pwa.campaignId === report)
        .map((pwa) => {
          const pwaClicks = allPwasStats.filter((click: any) => click.campaign_id === pwa.campaignId);

          const totalClicks = pwaClicks.reduce((sum: number, click: any) => sum + (click.clicks || 0), 0);

          const totalUniqueClicks = pwaClicks.reduce(
            (sum: number, click: any) => sum + (click.campaign_unique_clicks || 0),
            0
          );
          const totalCpc = pwaClicks.reduce((sum: number, click: any) => sum + (click.cpc || 0), 0);

          const totalInstalls = (
            installs && installs[pwa.campaignId]
              ? installs[pwa.campaignId].filter((transaction: ITransaction) => transaction.pwaId === pwa._id)
              : []
          ).length;

          const totalPushSubs = (
            pushSubscribes && pushSubscribes[pwa.campaignId]
              ? pushSubscribes[pwa.campaignId].filter(
                  (subscription: IPushSubscription) => subscription.pwa_id === pwa._id
                )
              : []
          ).length;

          const totalRegs = pwaClicks.reduce((sum: number, click: any) => sum + (click.leads || 0), 0);

          const totalDeposits = pwaClicks.reduce((sum: number, click: any) => sum + (click.sales || 0), 0);
          const allStoreStats = storeStats
            ?.filter((stats: any) => {
              return stats.keyword === pwa._id;
            })
            .reduce(
              (acc: any, click: any) => {
                const { campaign_unique_clicks, clicks } = click;
                return {
                  clicks: acc.clicks + clicks,
                  campaign_unique_clicks: acc.campaign_unique_clicks + campaign_unique_clicks,
                };
              },
              {
                campaign_unique_clicks: 0,
                clicks: 0,
              }
            );

          return {
            statsType: "global",
            campaign: pwa.appName,
            campaignId: pwa.campaignId,
            icon: pwa.creatives?.icon || "",
            clicks: totalClicks,
            installs: totalInstalls,
            push_subs: totalPushSubs,
            campaign_unique_clicks: totalUniqueClicks,
            regs: totalRegs,
            deposits: totalDeposits,
            cpc: totalCpc,
            storeStats: allStoreStats,
          };
        })
    : [];

  const dateToShow = report && report !== "empty" && showDaysStats ? selectedPwaStatisticsArray : allPwasStatistics;
  const isEmptyData = dateToShow.length === 0 || report === "empty";
  const needToShowReportButton = needNewReport && report !== "empty";
  const needToShowCheckbox = dateToShow.length !== 0 && report && report !== "empty";

  return (
    <Box>
      {loading ? (
        <Preloader customStyles={{ border: "none" }} />
      ) : needToShowReportButton ? (
        <Button
          sx={{
            marginTop: "18px",
            width: "200px",
            backgroundColor: "#F4701B",
            textTransform: "uppercase",
            "&:hover": {
              backgroundColor: "#F0670B",
            },
            color: "white",
          }}
          className="create_btn"
          variant="contained"
          aria-label="generate report"
          size="medium"
          onClick={buildReport}
        >
          {t("generateReport")}
        </Button>
      ) : (
        <>
          {view === "table" ? (
            <Box sx={{ maxWidth: "100vw", overflow: "auto" }}>
              {isEmptyData ? (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" fontWeight={400}>
                    {t("noitems")}
                  </Typography>
                </Grid>
              ) : (
                <>
                  {needToShowCheckbox && (
                    <CustomCheckbox
                      showDaysStats={showDaysStats}
                      setShowDaysStats={setShowDaysStats}
                      customLabel="detailsBydays"
                    />
                  )}
                  <ResizableStatsTable statsCardData={dateToShow} />
                </>
              )}
            </Box>
          ) : (
            <>
              {needToShowCheckbox && (
                <CustomCheckbox
                  showDaysStats={showDaysStats}
                  setShowDaysStats={setShowDaysStats}
                  customLabel="detailsBydays"
                />
              )}
              <Grid container spacing={2} rowSpacing={3}>
                {isEmptyData ? (
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" fontWeight={400}>
                      {t("noitems")}
                    </Typography>
                  </Grid>
                ) : (
                  <StatsCard statsCardData={dateToShow} />
                )}
              </Grid>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default StatsTable;
