import { Box } from "@mui/material";
import { DataGrid, GridRowsProp, useGridApiRef } from "@mui/x-data-grid";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import useDataGridColumns from "components/ResizableStatsTable/useDataGridColumns";
import { IStatsCardProps } from "interfaces/stats";
import { debounce } from "utils/debounce";

interface Dimension {
  maxWidth: number;
  minWidth: number;
  width: number;
  flex?: number;
}

interface ColumnVisibilityModel {
  [key: string]: boolean;
}

interface ColumnState {
  dimensions: Record<string, Dimension>;
  columnVisibilityModel: ColumnVisibilityModel;
  orderedFields: string[];
}

const ResizableStatsTable = ({ statsCardData }: IStatsCardProps) => {
  const apiRef = useGridApiRef();
  const [rowsToShow, setRowsToShow] = useState<Array<string | number>>([]);
  const [columnState, setColumnState] = useState<ColumnState | null>(null);

  const filterMenuOptions = statsCardData.map((card) => {
    if (!card.day) {
      return { day: null, appName: card.campaign, campaignId: card.campaignId, icon: card.icon || null };
    } else {
      return { day: card.day, appName: null, campaignId: null, icon: null };
    }
  });

  const filtredStatsCardData = statsCardData.filter((statsCard) => {
    if (statsCard.day) {
      return rowsToShow.includes(statsCard.day);
    } else if (statsCard.campaignId) {
      return rowsToShow.includes(statsCard.campaignId);
    }
    return false;
  });

  const rows: GridRowsProp = filtredStatsCardData.map((card, index) => {
    const storeClick = card?.storeStats?.clicks || 0;
    const storeUniqueClick = card?.storeStats?.campaign_unique_clicks || 0;
    const crInstalPercentage = card.installs ? ((storeUniqueClick / card.installs) * 100).toFixed(2) + "%" : "0.00%";
    const crPushPercentage = card.push_subs ? ((storeUniqueClick / card.push_subs) * 100).toFixed(2) + "%" : "0.00%";
    const crInstalPushPercentage =
      card.installs && card.push_subs ? ((card.installs / card.push_subs) * 100).toFixed(2) + "%" : "0.00%";
    const reopenings = card.clicks - card.campaign_unique_clicks || 0;
    return {
      id: card.day || card.campaignId,
      grouping: { appName: card.campaign, icon: card.icon, day: card.day },
      clicks: storeClick,
      uniques: card.campaign_unique_clicks,
      installs: card.installs,
      uniqueClicks: storeUniqueClick,
      push: card.push_subs,
      reopen: reopenings,
      regs: card.regs,
      deps: card.deposits,
      crInst: crInstalPercentage,
      crPush: crPushPercentage,
      crInstPush: crInstalPushPercentage,
    };
  });

  const columns = useDataGridColumns(filterMenuOptions, rowsToShow, setRowsToShow);

  const columsWidthArr =
    columnState && Object.entries(columnState).length !== 0
      ? Object.entries(columnState?.dimensions).reduce(
          (acc, [key, value]: any) => {
            acc[key] = value.width;
            return acc;
          },
          {} as Record<string, number>
        )
      : {};

  const resizedColumns = columns.map((column) => {
    if (columsWidthArr[column.field] !== undefined) {
      return { ...column, width: columsWidthArr[column.field] };
    }
    return column;
  });

  const saveSnapshot = useCallback(() => {
    if (apiRef?.current?.exportState && localStorage) {
      const { columns } = apiRef.current.exportState();
      localStorage.setItem("dataGridState", JSON.stringify(columns));
    }
  }, [apiRef]);

  const debounceSaveSnapshot = debounce(() => {
    if (apiRef?.current?.exportState && localStorage) {
      const { columns } = apiRef.current.exportState();
      localStorage.setItem("dataGridState", JSON.stringify(columns));
    }
  }, 1000);

  useEffect(() => {
    if (!statsCardData) {
      return;
    }
    const filterValuesArray = statsCardData
      ?.map((card) => card.day ?? card.campaignId ?? "")
      .filter((value) => value !== "");

    setRowsToShow(filterValuesArray);
  }, [statsCardData]);

  useLayoutEffect(() => {
    const stateFromLocalStorage = localStorage?.getItem("dataGridState");
    setColumnState(stateFromLocalStorage ? JSON.parse(stateFromLocalStorage) : {});

    window.addEventListener("beforeunload", saveSnapshot);

    return () => {
      window.removeEventListener("beforeunload", saveSnapshot);
      saveSnapshot();
    };
  }, [saveSnapshot, rowsToShow]);

  return (
    <Box sx={{ width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={resizedColumns}
        apiRef={apiRef}
        onColumnResize={debounceSaveSnapshot}
        disableRowSelectionOnClick
        hideFooter
        showCellVerticalBorder
        sx={{
          "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within":
            {
              outline: "none",
            },
          "& .MuiDataGrid-columnHeader": {
            backgroundColor: "#F6F7F9",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            color: "#6D6777",
          },
          "& .MuiDataGrid-columnSeparator": {
            opacity: "1 !important",
            color: "#D8D7D7",
          },
        }}
      />
    </Box>
  );
};

export default ResizableStatsTable;
