import React, { ChangeEvent } from "react";
import TrackSwitch from "./TrackSwitch";
import { AppDataMockup } from "interfaces/mockupApp";
import { useAppStore } from "store/appStore";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import CustomInput from "components/Input";

interface IModal {
  handleChangeSwith?: (name: keyof AppDataMockup) => void;
}

const ModalAdditional = ({ handleChangeSwith }: IModal) => {
  const { t } = useTranslation();
  const { appData, saveAppData } = useAppStore();

  const handleChange = (name: keyof AppDataMockup) => {
    if (handleChangeSwith) {
      handleChangeSwith(name);
    }
  };

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: keyof AppDataMockup
  ) => {
    const { value } = event.target;
    saveAppData({ [field]: value });
  };

  return (
    <div
      style={{
        paddingTop: "26px",
        border: "1px solid #EEEEEE",
        borderRadius: "10px",
        marginTop: "20px",
        fontFamily: "var(--cygreMedium), sans-serif",
      }}
    >
      <h2
        style={{
          fontFamily: "var(--cygreMedium), sans-serif",
          fontWeight: "500",
          fontSize: "16px",
          lineHeight: "32px",
          marginLeft: "20px",
          marginBottom: "38px",
        }}
      >
        {t("additionalsettings")}
      </h2>

      <Box sx={{ margin: "0px 15px 25px 15px" }}>
        <CustomInput
          margin="normal"
          required
          fullWidth
          id="unigueClicksTime"
          height="40px"
          placeholder={t("enterHours")}
          name="unigueClicksTime"
          label={t("uniqueClicksDuration")}
          value={appData.cookiesTtl}
          onlyNumbersValues={true}
          onChange={(event) => handleInputChange(event, "cookiesTtl")}
          customBgColor="#F6F7F9"
          iconEnd={<Typography sx={{ color: "#B4B1B9", fontSize: "14px" }}>{t("hours")}</Typography>}
        />
      </Box>

      <TrackSwitch
        description={t("showcookiesconsent")}
        label={t("cookies")}
        isSelected={appData.filesCookies}
        handleChange={() => {
          handleChange("filesCookies");
        }}
      />
      <TrackSwitch
        description={t("displayPWA")}
        label="Fullscreen"
        isSelected={appData.fullscreen}
        handleChange={() => {
          handleChange("fullscreen");
        }}
      />
      <TrackSwitch
        description={t("ignorecloak")}
        label={t("cloakignoring")}
        isSelected={appData.ignoringCloaca}
        handleChange={() => {
          handleChange("ignoringCloaca");
        }}
      />
    </div>
  );
};

export default ModalAdditional;
