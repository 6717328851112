import { Box, Typography } from "@mui/material";
import LogoIcon from "assets/icons/Logo";
import { useNavigate } from "react-router-dom";

export default function Logo() {
  const navigate = useNavigate();

  return (
    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} onClick={() => navigate("/")}>
      <LogoIcon />
      <Typography
        sx={{
          paddingLeft: "10px",
          display: {
            xs: "none",
            md: "block",
          },
        }}
        color="#000"
        fontSize={20}
      >
        DuckSay
      </Typography>
    </Box>
  );
}
