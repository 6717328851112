import { IPwaStatistics } from "interfaces/stats";

export const filterStatsBySelectedPeriod = (
  statsArr: any[],
  selectedRange: "today" | "1_month_ago" | "1_year_ago" | "custom" | string,
  fromDate: string | null,
  toDate: string | null
) => {
  const dateNow = new Date();

  if (selectedRange === "today") {
    const nowYear = dateNow.getFullYear();
    const nowMonth = dateNow.getMonth();
    const nowDay = dateNow.getDate();

    const filtredStatsArray = statsArr.filter((statsItem) => {
      const statsItemCreateAtDate = statsItem.createdAt || statsItem.created_at;
      const statsDate = new Date(statsItemCreateAtDate);

      return statsDate.getFullYear() === nowYear && statsDate.getMonth() === nowMonth && statsDate.getDate() === nowDay;
    });

    return filtredStatsArray;
  }

  if (selectedRange === "1_month_ago") {
    const oneMonthAgo = new Date(dateNow.getFullYear(), dateNow.getMonth() - 1, dateNow.getDate());
    const filtredStatsArray = statsArr.filter((statsItem) => {
      const statsItemCreateAtDate = statsItem.createdAt || statsItem.created_at;
      const statsDate = new Date(statsItemCreateAtDate);

      return statsDate >= oneMonthAgo && statsDate <= dateNow;
    });

    return filtredStatsArray;
  }

  if (selectedRange === "1_year_ago") {
    const oneYearAgo = new Date(dateNow.getFullYear() - 1, dateNow.getMonth(), dateNow.getDate());
    const filtredStatsArray = statsArr.filter((statsItem) => {
      const statsItemCreateAtDate = statsItem.createdAt || statsItem.created_at;
      const statsDate = new Date(statsItemCreateAtDate);

      return statsDate >= oneYearAgo && statsDate <= dateNow;
    });

    return filtredStatsArray;
  }

  if (selectedRange === "custom") {
    if (!fromDate && !toDate) return statsArr;

    const parseYearMonthDay = (str: string) => {
      const [year, month, day] = str.split("-").map(Number);
      return new Date(year, month - 1, day);
    };

    let start: any = fromDate ? parseYearMonthDay(fromDate).setHours(0, 0, 0, 0) : null;
    let end: any = toDate ? parseYearMonthDay(toDate) : null;

    if (end) end.setHours(23, 59, 59, 999);
    if (!end && fromDate) end = parseYearMonthDay(fromDate).setHours(23, 59, 59, 999);

    return statsArr.filter((statsItem) => {
      const statsItemCreateAtDate = statsItem.createdAt || statsItem.created_at;
      const statsDate = new Date(statsItemCreateAtDate);
      return (!start || statsDate >= start) && (!end || statsDate <= end);
    });
  }
};

export const checkDayStatisticsExists = (stats: Record<string, IPwaStatistics>, day: string, campaign_id: number) => {
  if (!stats[day] || stats[day].campaign_id !== campaign_id) {
    stats[day] = {
      statsType: "selected",
      campaign: "",
      campaign_id: campaign_id,
      clicks: 0,
      campaign_unique_clicks: 0,
      cpc: 0,
      installs: 0,
      push_subs: 0,
      deposits: 0,
      regs: 0,
      storeStats: { clicks: 0, campaign_unique_clicks: 0 },
    };
  }
  return stats[day];
};
