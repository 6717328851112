import React, { useEffect, useRef, useState } from "react";
import { Box, Card, CardContent, Link, Typography } from "@mui/material";
import useCountriesForFirstRegion from "mokcup/regionFirst";
import useCountriesForSecondRegion from "mokcup/regionSecond";
import useCountriesForThirdRegion from "mokcup/regionThird";

import { useTranslation } from "react-i18next";

import useModalStore from "store/modalStore";
import RegionModal from "./RegionModal";
import CustomButton from "components/Button";
import HeaderSection from "components/HeaderSection";
import DuckFootIcon from "assets/icons/DuckFootIcon";

interface IPriceCard {
  region: number;
  price: string;
  countries: string[];
  showButton?: boolean;
  isLayout: boolean;
}

export function PriceCard({ region, price, countries, showButton, isLayout = true }: IPriceCard) {
  const { t } = useTranslation();
  const { openModal } = useModalStore();
  const handleClickBtn = () => {
    openModal("signup");
  };
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const titleText = isLayout ? { xs: "48px", md: "54px" } : "37px";
  const regionTitle = isLayout
    ? { fontSize: "25px", color: "#2E263D", lineHeight: "35px", fontFamily: "var(--cygreMedium), sans-serif" }
    : {
        fontSize: { xs: "20px", lg: "24px" },
        color: "#2E263D",
        lineHeight: "35px",
        fontFamily: "var(--cygreMedium), sans-serif",
      };
  const installtext = isLayout
    ? { fontSize: { xs: "18px", lg: "20px" }, lineHeight: "23px", color: "#595959", textAlign: "left" }
    : { fontSize: "14px", lineHeight: "22px", color: "#595959" };
  return (
    <>
      <Card
        sx={{
          width: "100%",
          borderRadius: "24px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          background: "#fff",
          marginBottom: "16px",
          padding: { xs: isLayout ? "16px" : "8px", xl: isLayout ? "32px" : "8px" },
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
            <Box
              sx={{
                width: "56px",
                height: "56px",
                borderRadius: "14px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "linear-gradient(180deg, #F4701B, #f8b68b)",
                position: "relative",
                overflow: "hidden",
              }}
            >
              <Typography
                sx={{
                  fontSize: "34px",
                  lineHeight: "1",
                  zIndex: 1,
                  position: "relative",
                  bottom: "4px",
                }}
              >
                {region}
              </Typography>
            </Box>

            <Typography sx={{ paddingLeft: "20px", ...regionTitle }}>
              {t("region")} {region}
            </Typography>
          </Box>
          <Link
            component="button"
            sx={{
              padding: "32px 0 20px",
              color: "#595959",
              textAlign: "left",
              fontSize: { xs: "16px", xl: "20px" },
              textDecorationColor: "#595959",
            }}
            onClick={handleOpen}
          >
            {t("viewregions")}
          </Link>
          <Box
            sx={{
              padding: isLayout ? "24px 0 40px" : "0",
              display: "flex",
              justifyContent: "center",
              alignItems: { xs: "center", lg: "baseline" },
              flexDirection: { xs: "column", lg: "row" },
            }}
          >
            <Box
              sx={{
                fontSize: titleText,
                fontFamily: "var(--cygreBold)",
                paddingRight: "10px",
              }}
            >
              ${price}
            </Box>
            <Typography sx={{ ...installtext }}>/{t("perinstallation")}</Typography>
          </Box>
          {showButton && (
            <CustomButton
              width="100%"
              textButton={t("start")}
              customBgColor="#000"
              customFontSize="20px"
              onClick={handleClickBtn}
              customHoverColor="#F4701B"
            />
          )}
        </CardContent>
      </Card>
      <RegionModal open={open} setOpen={setOpen} region={region} countries={countries} />
    </>
  );
}

export default function Price() {
  const { t } = useTranslation();
  const [showIcon1, setShowIcon1] = useState(false);
  const [showIcon2, setShowIcon2] = useState(false);
  const [showIcon3, setShowIcon3] = useState(false);
  const [showIcon4, setShowIcon4] = useState(false);
  const countriesForFirstRegion = useCountriesForFirstRegion();
  const countriesForSecondRegion = useCountriesForSecondRegion();
  const countriesForThirdRegion = useCountriesForThirdRegion();

  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setTimeout(() => setShowIcon1(true), 0);
            setTimeout(() => setShowIcon2(true), 500);
            setTimeout(() => setShowIcon3(true), 1000);
            setTimeout(() => setShowIcon4(true), 1500);

            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.66 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);
  const priceData = [
    { region: 1, price: "0,09", countries: countriesForFirstRegion },
    { region: 2, price: "0,07", countries: countriesForSecondRegion },
    { region: 3, price: "0,03", countries: countriesForThirdRegion },
  ];

  return (
    <Box id="price" ref={sectionRef} sx={{ width: "100%", padding: "0 0 150px", position: "relative" }}>
      <HeaderSection title={t("price")} />
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: { xs: "column", md: "row" },
          gap: "16px",
          justifyContent: { xs: "center", xl: "space-between" },
          paddingTop: { xs: "40px", md: "64px" },
          position: "relative",
          zIndex: 1,
        }}
      >
        {priceData.map((item, index) => (
          <Box
            key={index}
            sx={{
              flexBasis: { xs: "100%", md: "calc(33.33% - 12px)" },
              maxWidth: { xs: "100%", md: "calc(33.33% - 12px)" },
              boxSizing: "border-box",
            }}
          >
            <PriceCard
              region={item.region}
              price={item.price}
              showButton={true}
              countries={item.countries}
              isLayout={true}
            />
          </Box>
        ))}
      </Box>

      {showIcon1 && (
        <Box
          className="icon-animation"
          sx={{
            position: "absolute",
            top: "80%",
            left: { xs: "51%", md: "40%", lg: "15%" },
            transform: "rotate(49deg)",
            opacity: showIcon1 ? 1 : 0,
            transition: "opacity 0.5s ease-in-out",
          }}
        >
          <DuckFootIcon />
        </Box>
      )}
      {showIcon2 && (
        <Box
          className="icon-animation"
          sx={{
            position: "absolute",
            top: "81%",
            left: { xs: "45%", md: "35%", lg: "10%" },
            transform: "rotate(92deg)",
            opacity: showIcon2 ? 1 : 0,
            transition: "opacity 0.5s ease-in-out",
          }}
        >
          <DuckFootIcon />
        </Box>
      )}
      {showIcon3 && (
        <Box
          className="icon-animation"
          sx={{
            position: "absolute",
            top: "83%",
            left: { xs: "36%", md: "30%", lg: "5%", xl: "3%" },
            transform: "rotate(124deg)",
            opacity: showIcon3 ? 1 : 0,
            transition: "opacity 0.5s ease-in-out",
          }}
        >
          <DuckFootIcon />
        </Box>
      )}
      {showIcon4 && (
        <Box
          className="icon-animation"
          sx={{
            position: "absolute",
            top: "85%",
            left: { xs: "23%", md: "20%", lg: "1%", xl: "-2%" },
            transform: "rotate(145deg)",
            opacity: showIcon4 ? 1 : 0,
            transition: "opacity 0.5s ease-in-out",
          }}
        >
          <DuckFootIcon />
        </Box>
      )}
    </Box>
  );
}
