import { Checkbox, FormControlLabel } from "@mui/material";
import { t } from "i18next";

interface CustomCheckboxProps {
  setShowDaysStats: (value: boolean) => void;
  showDaysStats: boolean;
  customLabel: string;
}

const CustomCheckbox = ({ showDaysStats, setShowDaysStats, customLabel }: CustomCheckboxProps) => {
  return (
    <FormControlLabel
      label={t(customLabel)}
      control={
        <Checkbox
          sx={{
            color: "#C9D0D8",
            "&.Mui-checked": {
              color: "#F4701B",
            },
          }}
          checked={showDaysStats}
          onChange={(event) => setShowDaysStats(event.target.checked)}
        />
      }
      sx={{
        marginBottom: "20px",
        "& .MuiFormControlLabel-label": {
          fontFamily: "var(--cygreRegular), sans-serif",
          fontSize: "14px",
          color: "#2E263D",
        },
      }}
    />
  );
};

export default CustomCheckbox;
