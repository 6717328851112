interface TimezoneSelectOption {
  name: string;
  value: string;
  label: string;
}

export const timezoneSelectOptions: TimezoneSelectOption[] = [
  { name: "UTC", value: "UTC", label: "(GMT+0:00) UTC" },
  { name: "Europe/London", value: "Europe/London", label: "(GMT+0:00) Europe/London" },
  { name: "Europe/Berlin", value: "Europe/Berlin", label: "(GMT+1:00) Europe/Berlin" },
  { name: "Europe/Madrid", value: "Europe/Madrid", label: "(GMT+1:00) Europe/Madrid" },
  { name: "Europe/Moscow", value: "Europe/Moscow", label: "(GMT+3:00) Europe/Moscow" },
  { name: "America/New_York", value: "America/New_York", label: "(GMT-5:00) America/New_York" },
  { name: "America/Chicago", value: "America/Chicago", label: "(GMT-6:00) America/Chicago" },
  { name: "America/Denver", value: "America/Denver", label: "(GMT-7:00) America/Denver" },
  { name: "America/Los_Angeles", value: "America/Los_Angeles", label: "(GMT-8:00) America/Los_Angeles" },
  { name: "Asia/Dubai", value: "Asia/Dubai", label: "(GMT+4:00) Asia/Dubai" },
  { name: "Asia/Kolkata", value: "Asia/Kolkata", label: "(GMT+5:30) Asia/Kolkata" },
  { name: "Asia/Bangkok", value: "Asia/Bangkok", label: "(GMT+7:00) Asia/Bangkok" },
  { name: "Asia/Shanghai", value: "Asia/Shanghai", label: "(GMT+8:00) Asia/Shanghai" },
  { name: "Asia/Tokyo", value: "Asia/Tokyo", label: "(GMT+9:00) Asia/Tokyo" },
  { name: "Australia/Sydney", value: "Australia/Sydney", label: "(GMT+11:00) Australia/Sydney" },
];
