function CloseButtonIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 19L10 10M10 10L1 1M10 10L19.0001 1M10 10L1 19.0001"
        stroke="#2E263D"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default CloseButtonIcon;
