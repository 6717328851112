import React from "react";
import { Button, Box, CircularProgress, ButtonProps } from "@mui/material";
import { styled } from "@mui/system";

interface CustomButtonProps extends ButtonProps {
  loading?: boolean;
  loginDisabled?: boolean;
  textButton: string;
  customBgColor?: string;
  customHoverColor?: string;
  customFontFamily?: string;
  customBorderRadius?: string;
  customFontSize?: string;
  customPadding?: string;
  height?: number;
  customMargin?: string;
  customColor?: string;
  customBorderColor?: string;
  variant?: "text" | "outlined" | "contained";
  startIconSrc?: React.ReactNode;
  width?: number | string;
  lineHeight?: number | string;
  customTextTransform?: string;
}

const StyledButton = styled(Button)(
  ({ theme }) => ({
    borderRadius: "50px",
    fontSize: "14px",
    textTransform: "uppercase",
    backgroundColor: "inherit",
    boxShadow: "none",
    color: "info",
    fontFamily: "var(--cygreMedium), sans-serif",
  }),
  ({
    color,
    customFontSize,
    customBgColor,
    customHoverColor,
    customFontFamily,
    customBorderRadius,
    customTextTransform,
    customColor,
    height,
    customBorderColor,
    width,
    customMargin,
  }: any) => ({
    borderRadius: customBorderRadius,
    fontSize: customFontSize,
    backgroundColor: customBgColor,
    color: customColor,
    alignItems: "center",
    "&:hover": {
      backgroundColor: customHoverColor,
      boxShadow: "none",
      color: customColor,
    },
    fontFamily: customFontFamily,
    border: customBorderColor ? `1px solid ${customBorderColor}` : "1px solid transparent",
    height: height ? height : 57,
    width: width ? width : 120,
    margin: customMargin,
    textTransform: customTextTransform,
    "&:disabled": {
      backgroundColor: "#F6F6F6",
      color: "#D1D1D1",
    },
  })
);

const CustomButton: React.FC<CustomButtonProps> = ({
  loading,
  loginDisabled,
  customBgColor,
  customHoverColor,
  customFontFamily,
  customFontSize,
  customBorderRadius,
  customColor,
  customPadding,
  height,
  width,
  textButton,
  customBorderColor,
  customMargin,
  variant = "contained",
  startIconSrc,
  lineHeight,
  ...props
}) => {
  return (
    // @ts-ignore
    <StyledButton
      type="submit"
      fullWidth
      color="info"
      sx={{
        border: customBorderColor ? `1px solid ${customBorderColor}` : "1px solid transparent",
        fontSize: customFontSize,
        height: height ? height : 57,
        width: width ? width : 120,
        margin: customMargin,
        color: customColor,
        padding: customPadding,
        // lineHeight: lineHeight || 1.8,
        lineHeight: 1,
        minWidth: "40px",
        borderRadius: customBorderRadius,
        backgroundColor: customBgColor,
        "&:hover": {
          backgroundColor: customHoverColor,
        },
        fontFamily: customFontFamily,
      }}
      disabled={loginDisabled || loading}
      variant={variant}
      {...props}
    >
      {loading ? (
        <CircularProgress size={24} />
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {startIconSrc && startIconSrc}
          <span>{textButton}</span>
        </Box>
      )}
    </StyledButton>
  );
};

export default CustomButton;
