import { createTheme } from "@mui/material/styles";

const baselightTheme = createTheme({
  direction: "ltr",
  breakpoints: {
    values: {
      xs: 360,
      sm: 600,
      md: 768,
      lg: 1024,
      xl: 1440,
    },
  },
  palette: {
    primary: {
      main: "#F4701B",
      light: "#FFA16A",
      dark: "#f4701b",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#fff",
    },
    success: {
      main: "#13DEB9",
      light: "#E6FFFA",
      dark: "#02b3a9",
      contrastText: "#ffffff",
    },
    info: {
      main: "#539BFF",
      light: "#EBF3FE",
      dark: "#1682d4",
      contrastText: "#ffffff",
    },
    error: {
      main: "#E02B00",
    },
    warning: {
      main: "#FFAE1F",
      light: "#FEF5E5",
      dark: "#ae8e59",
      contrastText: "#ffffff",
    },
    grey: {
      100: "#F2F6FA",
      200: "#EAEFF4",
      300: "#DFE5EF",
      400: "#7C8FAC",
      500: "#5A6A85",
      600: "#2A3547",
    },
    text: {
      primary: "#000",
      secondary: "#5A6A85",
    },
    action: {
      disabledBackground: "rgba(73,82,88,0.12)",
      hoverOpacity: 0.02,
      hover: "#f6f9fc",
    },
    divider: "#e5eaef",
  },

  typography: {
    fontFamily: "var(--cygreRegular), sans-serif",

    h1: {
      fontWeight: 500,
      fontSize: "56px",
      lineHeight: "75px",
    },
    h2: {
      fontWeight: 600,
      fontSize: "1.875rem",
      lineHeight: "2.25rem",
    },
    h3: {
      fontWeight: 600,
      fontSize: "1.5rem",
      lineHeight: "1.75rem",
    },
    h4: {
      fontSize: "25px",
      color: "#2E263D",
      lineHeight: "35px",
      fontFamily: "var(--cygreMedium), sans-serif",
    },
    h5: { fontFamily: "var(--cygreMedium), sans-serif", fontSize: "20px", lineHeight: "25px", color: "#2E263D" },
    h6: {
      fontSize: "14px",
      color: "#2E263D",
    },
    button: {
      textTransform: "capitalize",
      fontWeight: 400,
    },
    body1: {
      fontSize: "18px",
      fontWeight: 400,
      lineHeight: "23px",
      color: "inherit",
      textAlign: "left",
    },
    body2: {
      fontSize: "16px",
      lineHeight: "19px",
      color: "#2E263D",
    },
    subtitle1: {
      fontSize: "23px",
      fontFamily: "var(--cygreBold)",
      lineHeight: "65px",
    },
    subtitle2: {
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "25px",
      textAlign: "center",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ".MuiPaper-elevation9, .MuiPopover-root .MuiPaper-elevation": {
          boxShadow: "rgb(145 158 171 / 30%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px !important",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "7px",
        },
      },
    },
    MuiPopover: {
      defaultProps: {
        container: document.getElementById("root"),
      },
    },
    MuiModal: {
      defaultProps: {
        container: document.getElementById("root"),
      },
    },
  },
});

export { baselightTheme };
