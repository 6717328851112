import { Box, Button, styled } from "@mui/material";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ViewModuleOutlinedIcon from "@mui/icons-material/ViewModuleOutlined";
import MenuIcon from "@mui/icons-material/Menu";

interface ViewSwitcherProps {
  toggleView: () => void;
  view: "table" | "grid";
}

const ViewSwitcher = ({ view, toggleView }: ViewSwitcherProps) => {
  const SwitchButton = styled(Button)(({ active }: { active: boolean }) => ({
    borderRadius: 10,
    backgroundColor: active ? "#ffffff" : "transparent",
    padding: "0px 5px",
    minWidth: "auto",
    color: "#F4701B",
    "&:hover": {
      backgroundColor: "#ffffff",
    },
  }));

  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#F6F7FB",
        padding: "5px",
        borderRadius: "10px",
        width: "74px",
        height: "40px",
        margin: "auto 0 0 auto",
      }}
    >
      <SwitchButton onClick={() => view !== "grid" && toggleView()} active={view === "grid"}>
        {view === "grid" ? <ViewModuleIcon /> : <ViewModuleOutlinedIcon sx={{ color: "#6D6777" }} />}
      </SwitchButton>
      <SwitchButton onClick={() => view !== "table" && toggleView()} active={view === "table"}>
        {view === "table" ? <MenuIcon /> : <MenuIcon sx={{ color: "#6D6777" }} />}
      </SwitchButton>
    </Box>
  );
};

export default ViewSwitcher;
