import i18n from "i18n";
import moment from "moment";

export function formatDate(dateString: moment.MomentInput) {
  return moment(dateString).format("DD/MM/YYYY");
}

export function formatTime(dateString: moment.MomentInput): string {
  return moment(dateString).format("DD.MM.YYYY HH:mm:ss");
}

export const convertTimestampToDate = (timestamp: number) => {
  const date = new Date(timestamp * 1000);
  const day = String(date.getUTCDate()).padStart(2, "0");
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const year = date.getUTCFullYear();
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");

  return `${day}.${month}.${year} ${hours}:${minutes}`;
};

export const getLocaleFormat = (language: string): string => {
  switch (language) {
    case "ua":
      return "uk-UA";
    case "en":
      return "en-US";
    case "ru":
      return "ru-RU";
    default:
      return "en-US";
  }
};

export const formatDateByLocale = (dateString: any): string => {
  const date = new Date(dateString);
  const currentLanguage = getLocaleFormat(i18n.language);
  return date.toLocaleDateString(currentLanguage, {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
};

export const formatDateByLocaleDDMM = (dateString: any): string => {
  const date = new Date(dateString);
  const currentLanguage = getLocaleFormat(i18n.language);
  const replacedDate = date
    .toLocaleDateString(currentLanguage, {
      day: "2-digit",
      month: "short",
    })
    .replace(" ", "\n");
  return replacedDate;
};

export const formatMMDDYYYY = (date: Date | null, firstDayOfMonth: boolean = false) => {
  if (!date) return null;

  if (firstDayOfMonth) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    return `${year}-${month}-01`;
  }

  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const year = String(date.getFullYear());
  return `${year}-${month}-${day}`;
};

export const generateDaysOfMonth = (year: number, month: number) => {
  const currentLanguage = getLocaleFormat(i18n.language);
  const totalDaysInMonth = new Date(year, month + 1, 0).getDate();
  const monthName = new Date(year, month).toLocaleString(currentLanguage, { month: "short" });
  return Array.from({ length: totalDaysInMonth }, (_, i) => `${i + 1} \n ${monthName}`);
};

export const generateHoursOfDay = () => Array.from({ length: 24 }, (_, i) => `${i}:00`);

export const generateMonthsOfYear = () => Array.from({ length: 12 }, (_, i) => `${i + 1}`);

export const getDaysInMonth = (date: Date = new Date()): number => {
  const year = date.getFullYear();
  const month = date.getMonth();
  return new Date(year, month + 1, 0).getDate();
};
