import React, { useEffect } from "react";
import ModalTrack from "./ModalTrack";
import ModalAdditional from "./ModalAdditional";
import { Box } from "@mui/material";
import CustomButton from "components/Button";
import { useTranslation } from "react-i18next";
import useIdStore from "store/pwaId";
import { useNavigate } from "react-router-dom";
import useSetupStore from "store/setupSettingsStore";
import { useAppStore } from "store/appStore";
import { AppDataMockup } from "interfaces/mockupApp";
import usePwaSave from "hooks/usePwaSave";

export function generateId() {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < 6; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters[randomIndex];
  }
  return result;
}

const TrackingTab = () => {
  const { appData, saveAppData, clearAppData } = useAppStore();
  const { t } = useTranslation();
  const { clearId } = useIdStore();
  const navigate = useNavigate();
  const setSettingProcess = useSetupStore((state) => state.setSettingProcess);
  const setStepStatus = useSetupStore((state) => state.setStepStatus);
  const resetSteps = useSetupStore((state) => state.resetAllSteps);
  const { isLoading, handlePwa } = usePwaSave();

  useEffect(() => {
    setStepStatus("tracking", "inprogress");
    return () => {
      setStepStatus("tracking", "ready");
    };
  }, [setStepStatus]);

  const handleChangeSwith = async (name: keyof AppDataMockup) => {
    saveAppData({
      [name]: !appData[name],
    });
  };

  async function handleDeletePwa() {
    navigate("/pwa");
    clearAppData();
    setSettingProcess(false);
    resetSteps();
    clearId();
  }

  return (
    <Box>
      <ModalTrack />
      <ModalAdditional handleChangeSwith={handleChangeSwith} />
      <Box sx={{ display: "flex", pt: 2 }}>
        <Box sx={{ display: "flex" }}>
          <CustomButton
            textButton={t("cancel")}
            height={40}
            width={184}
            customBgColor="#FFFFFF"
            customHoverColor="#FFFFFF"
            customBorderRadius="8px"
            customFontSize="14px"
            customColor="#6D6777"
            customBorderColor="#C9D0D8"
            customMargin="0px 10px 0px 0px"
            onClick={handleDeletePwa}
          />
          <CustomButton
            textButton={t("save")}
            height={40}
            width={184}
            customBgColor="#F4701B"
            customHoverColor="#F4701B"
            customBorderRadius="8px"
            customFontSize="14px"
            loading={isLoading}
            onClick={handlePwa}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default TrackingTab;
