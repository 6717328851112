import { getToken } from "utils/getToken";
import useFetch from "hooks/useFetch";
import { Box, Grid, Typography } from "@mui/material";
import CustomButton from "components/Button";
import CustomInput from "components/Input";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { BootstrapTooltip } from "components/PWATabs/mainTab/CommentsTable";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const TrafficManagementPage = () => {
  const token = getToken();
  const { t } = useTranslation();
  const [currentTrafficLink, setCurrentTrafficLink] = useState<string>("");
  const [trafficInputValue, setTrafficInputValue] = useState<string>("");
  const linkRegex = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)(:\d+)?(\/[^\s]*)?$/;

  const { triggerFetch: getCurrentLink } = useFetch({
    url: "admin/traffic",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const { loading: updateSettingsLoading, triggerFetch: updateGlobalSettings } = useFetch({
    url: "admin/settings",
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const getCurrentTrafficLink = async () => {
    const { currentInappropriateTrafficLink } = await getCurrentLink();

    if (currentInappropriateTrafficLink) {
      setCurrentTrafficLink(currentInappropriateTrafficLink);
    }
  };

  const handleTrafficInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;
    setTrafficInputValue(value);
  };

  const handleSubmit = async () => {
    if (!trafficInputValue) {
      toast.error(t("emptyTrafficInputValue"));
      return;
    }

    if (!linkRegex.test(trafficInputValue)) {
      toast.error(t("invalidTrafficLink"));
      return;
    }

    const newSettingsValue = {
      inappropriateTrafficLink: trafficInputValue,
    };
    const updatedGlobalSettings = await updateGlobalSettings(newSettingsValue);

    if (updatedGlobalSettings.success) {
      setCurrentTrafficLink(trafficInputValue);
      setTrafficInputValue("");
      toast.success(t("successfulTrafficLinkSave"));
    } else {
      toast.error(t("unsuccessfulTrafficLinkSave"));
    }
  };

  const handleClearTrafficLink = async () => {
    if (!currentTrafficLink) {
      return;
    }

    const settingsWithEmptyTrafficLink = {
      inappropriateTrafficLink: "",
    };
    const emptyTrafficLink = await updateGlobalSettings(settingsWithEmptyTrafficLink);

    if (emptyTrafficLink.success) {
      setCurrentTrafficLink("");
      toast.success(t("successfulTrafficLinkDelete"));
    } else {
      toast.error(t("unsuccessfulTrafficLinkDelete"));
    }
  };

  useEffect(() => {
    void getCurrentTrafficLink();
  }, []);

  return (
    <Grid container>
      <Typography variant="h4" sx={{ fontSize: "24px", marginBottom: "20px" }}>
        {t("trafficPageTitle")}
      </Typography>
      <Grid container item sx={{ marginBottom: "20px" }}>
        <Grid item xs={11} lg={11.5} sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <CustomInput
            required
            disabled
            customWidth="100%"
            id="trafficManagement"
            height="40px"
            placeholder={t("emptyData")}
            value={currentTrafficLink}
            name="trafficManagement"
            label={t("currentTrafficLink")}
            customBgColor="#F6F7F9"
          />
        </Grid>
        <Grid item xs={1} lg={0.5} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }}>
          <BootstrapTooltip title={t("delete")} placement="top">
            <Box
              sx={{
                maxHeight: " 40px",
                display: "flex",
                cursor: "pointer",
              }}
              onClick={handleClearTrafficLink}
            >
              <DeleteOutlineIcon
                sx={{
                  width: " 30px",
                  height: "40px",
                  color: "#6D6777",
                  "&:hover": {
                    color: "#F4701B",
                  },
                }}
              />
            </Box>
          </BootstrapTooltip>
        </Grid>
      </Grid>
      <Grid container item spacing={2} sx={{ display: "flex", alignItems: "flex-end" }}>
        <Grid item xs={9} sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <CustomInput
            required
            customWidth="100%"
            id="trafficManagement"
            height="40px"
            placeholder={t("setInappropriateTraffic")}
            value={trafficInputValue}
            name="trafficManagement"
            label={t("changeTrafficLink")}
            onChange={(event) => {
              handleTrafficInputChange(event);
            }}
            customBgColor="#F6F7F9"
          />
        </Grid>
        <Grid item xs={3}>
          <CustomButton
            textButton={t("save")}
            height={40}
            width={"100%"}
            customBgColor="#F4701B"
            customHoverColor="#F4701B"
            customBorderRadius="8px"
            customFontSize="12px"
            customPadding="0"
            loading={updateSettingsLoading}
            onClick={handleSubmit}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TrafficManagementPage;
