import { MenuOptions } from "interfaces/stats";
import { useRef, useState } from "react";
import { Box, Button, Menu, MenuItem, MenuProps, Typography } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import { FilterMunuList } from "./FilterMenuList";
// import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { t } from "i18next";

interface ICustomColumnMenuProps {
  filterMenuOptions: MenuOptions[];
  rowsToShow: (string | number)[];
  setRowsToShow: (rowsArr: (string | number)[]) => void;
}

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    container={document.getElementById("root")}
    transformOrigin={{
      vertical: "top",
      horizontal: 62,
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(2),
    minWidth: 180,
    color: "rgb(55, 65, 81)",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
    ...theme.applyStyles("dark", {
      color: theme.palette.grey[300],
    }),
  },
}));

const CustomColumnMenu = ({ filterMenuOptions, rowsToShow, setRowsToShow }: ICustomColumnMenuProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const columnRef = useRef(null);
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState<boolean>(false);
  const [isColumnMenuOpen, setIsColumnMenuOpen] = useState<boolean>(false);

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    const { id } = event.currentTarget;

    if (id === "filterMenu") {
      setIsFilterMenuOpen((prev) => !prev);
    } else if (id === "columnMenu") {
      setIsColumnMenuOpen((prev) => !prev);
    }
    setIsMenuOpen(false);
  };

  const handleFilterBtnClick = (rowsArr: (string | number)[]) => {
    setRowsToShow(rowsArr);
    setIsFilterMenuOpen(false);
    setIsColumnMenuOpen(false);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setIsMenuOpen(true);
  };

  const handleClose = () => setIsMenuOpen(false);

  return (
    <Box ref={columnRef} sx={{ display: "flex" }}>
      <Typography variant="subtitle2" fontWeight={500} sx={{ color: "#6D6777" }}>
        {t("grouping")}
      </Typography>
      <Button
        sx={{
          marginLeft: "12px",
          padding: 0,
          minWidth: 0,
          width: "30px",
          borderRadius: "50%",
        }}
        onClick={handleMenuClick}
      >
        <MoreVertIcon
          sx={{
            color: "#6D6777",
            "&:hover": {
              color: "#F4701B",
            },
          }}
        />
      </Button>
      <StyledMenu open={isMenuOpen} anchorEl={columnRef.current} onClose={handleClose}>
        <MenuItem onClick={handleMenuItemClick} id="filterMenu" disableRipple>
          <FilterAltIcon />
          Filter
        </MenuItem>
        {
          //*TODO Add columns management logic ! //
          /* <MenuItem onClick={handleMenuItemClick} id="columnMenu" disableRipple>
          <ViewWeekIcon />
          Manage columns
        </MenuItem> */
          //*TODO Add columns management logic ! //
        }
      </StyledMenu>
      {isFilterMenuOpen && (
        <StyledMenu open={isFilterMenuOpen} anchorEl={columnRef.current} onClose={() => setIsFilterMenuOpen(false)}>
          <FilterMunuList
            menuOptions={filterMenuOptions}
            rowsToShow={rowsToShow}
            handleFilterBtnClick={handleFilterBtnClick}
          />
        </StyledMenu>
      )}
    </Box>
  );
};

export default CustomColumnMenu;
