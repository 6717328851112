import React, { useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./basic-styles.scss";

interface IDatePickerRange {
  onChangeCallback: (dates: any) => void;
  error?: boolean;
}

export const DatePickerRange = ({ onChangeCallback, error }: IDatePickerRange) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const onChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    onChangeCallback(dates);
  };

  return (
    <div className={`react-datepicker__input-container ${error ? "error" : ""}`}>
      <DatePicker
        open={true}
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate as any}
        selectsRange
        withPortal
        maxDate={new Date()}
        monthsShown={2}
        onKeyDown={(e) => e.preventDefault()}
      />
    </div>
  );
};
