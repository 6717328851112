import { GridColDef, GridColumnHeaderParams, GridRenderCellParams } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import CustomColumnMenu from "./CustomColumnMenu";
import CustomGroupingField from "./CustomGroupingField";
import { MenuOptions } from "interfaces/stats";

const useDataGridColumns = (
  filterMenuOptions: MenuOptions[],
  rowsToShow: (string | number)[],
  setRowsToShow: (rowsArr: (string | number)[]) => void
): GridColDef[] => {
  const { t } = useTranslation();

  return [
    {
      field: "grouping",
      renderHeader: (params: GridColumnHeaderParams): JSX.Element => (
        <CustomColumnMenu filterMenuOptions={filterMenuOptions} rowsToShow={rowsToShow} setRowsToShow={setRowsToShow} />
      ),
      renderCell: (params: GridRenderCellParams): JSX.Element => (
        <CustomGroupingField appName={params.value?.appName} icon={params.value?.icon} day={params.value?.day} />
      ),
      type: "string",
      width: 180,
      disableColumnMenu: true,
      hideSortIcons: true,
      sortable: false,
    },
    {
      field: "clicks",
      headerName: t("clicks"),
      type: "number",
      width: 80,
      disableColumnMenu: true,
      hideSortIcons: true,
      sortable: false,
    },
    {
      field: "uniques",
      headerName: t("uniques"),
      type: "number",
      width: 130,
      disableColumnMenu: true,
      hideSortIcons: true,
      sortable: false,
    },
    {
      field: "installs",
      headerName: t("installs"),
      type: "number",
      width: 80,
      disableColumnMenu: true,
      hideSortIcons: true,
      sortable: false,
    },
    {
      field: "uniqueClicks",
      headerName: t("uniqueClicks"),
      type: "number",
      width: 130,
      disableColumnMenu: true,
      hideSortIcons: true,
      sortable: false,
    },
    {
      field: "push",
      headerName: t("push"),
      type: "number",
      width: 150,
      disableColumnMenu: true,
      hideSortIcons: true,
      sortable: false,
    },
    {
      field: "reopen",
      headerName: t("reopen"),
      type: "number",
      width: 100,
      disableColumnMenu: true,
      hideSortIcons: true,
      sortable: false,
    },
    {
      field: "regs",
      headerName: t("regs"),
      type: "number",
      width: 80,
      disableColumnMenu: true,
      hideSortIcons: true,
      sortable: false,
    },
    {
      field: "deps",
      headerName: t("deps"),
      type: "number",
      width: 80,
      disableColumnMenu: true,
      hideSortIcons: true,
      sortable: false,
    },
    {
      field: "crInst",
      headerName: t("crInst"),
      type: "number",
      width: 130,
      disableColumnMenu: true,
      hideSortIcons: true,
      sortable: false,
    },
    {
      field: "crPush",
      headerName: t("crPush"),
      type: "number",
      width: 130,
      disableColumnMenu: true,
      hideSortIcons: true,
      sortable: false,
    },
    {
      field: "crInstPush",
      headerName: t("crInstPush"),
      type: "number",
      width: 130,
      disableColumnMenu: true,
      hideSortIcons: true,
      sortable: false,
    },
  ];
};

export default useDataGridColumns;
