function Deps() {
  return (
    <div
      style={{
        background: "rgba(255, 208, 0, 0.274)",
        width: "56px",
        height: "56px",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="none" viewBox="0 0 30 30">
        <path
          fill="#FFBB00"
          d="M15.003 29.25c-1.971 0-3.824-.374-5.558-1.122a14.392 14.392 0 01-4.526-3.045 14.391 14.391 0 01-3.047-4.524C1.124 18.826.75 16.973.75 15.003c0-1.971.374-3.824 1.122-5.558a14.393 14.393 0 013.045-4.526 14.392 14.392 0 014.524-3.047C11.174 1.124 13.027.75 14.997.75c1.971 0 3.824.374 5.558 1.122a14.394 14.394 0 014.526 3.045 14.392 14.392 0 013.047 4.524c.748 1.733 1.122 3.586 1.122 5.556 0 1.971-.374 3.824-1.122 5.558a14.394 14.394 0 01-3.045 4.526 14.392 14.392 0 01-4.524 3.047c-1.733.748-3.586 1.122-5.556 1.122zM15 27c3.35 0 6.188-1.163 8.512-3.488C25.837 21.188 27 18.35 27 15c0-3.35-1.163-6.188-3.488-8.512C21.188 4.162 18.35 3 15 3c-3.35 0-6.188 1.162-8.512 3.487C4.162 8.813 3 11.65 3 15c0 3.35 1.162 6.188 3.487 8.512C8.813 25.837 11.65 27 15 27zm-.037-1.788a.981.981 0 00.716-.308.981.981 0 00.308-.716v-.794a6.612 6.612 0 003.16-1.311c.943-.716 1.415-1.802 1.415-3.258 0-1.05-.31-1.979-.93-2.787-.619-.807-1.828-1.536-3.628-2.186-1.577-.538-2.624-1.005-3.142-1.399-.517-.394-.775-.945-.775-1.653 0-.708.264-1.283.794-1.725.53-.442 1.261-.663 2.194-.663.558 0 1.046.104 1.464.312.418.21.772.487 1.06.835.183.224.394.38.635.468.24.088.468.085.682-.011.294-.116.494-.313.602-.592.107-.279.08-.535-.081-.767a5.25 5.25 0 00-1.49-1.412 4.042 4.042 0 00-1.885-.64v-.793a.982.982 0 00-.308-.716.981.981 0 00-.716-.308.981.981 0 00-.717.308.982.982 0 00-.308.716v.794c-1.278.246-2.26.765-2.946 1.556-.686.791-1.029 1.67-1.029 2.638 0 1.127.342 2.034 1.024 2.72.683.687 1.796 1.307 3.338 1.86 1.594.585 2.683 1.11 3.267 1.574.583.465.875 1.088.875 1.871 0 .94-.34 1.624-1.018 2.05-.679.425-1.445.638-2.299.638a3.6 3.6 0 01-1.932-.533c-.575-.356-1.057-.87-1.446-1.543a1.14 1.14 0 00-.614-.533 1.046 1.046 0 00-.757.01c-.266.096-.46.281-.584.553a.876.876 0 00-.004.78c.42.84.967 1.513 1.642 2.016.675.504 1.478.856 2.408 1.056v.869c0 .273.103.511.308.716a.981.981 0 00.716.308z"
        ></path>
      </svg>
    </div>
  );
}

export default Deps;
