import { useTheme, useMediaQuery } from "@mui/material";

const useIsDesktop = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));

  return isTablet;
};

export default useIsDesktop;
