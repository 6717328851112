import { Box, Divider, Drawer, IconButton, Button } from "@mui/material";
import SidebarItems from "./sidebarItems";
import { useEffect } from "react";
import IconDuck from "assets/IconDuck.png";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import useIsTablet from "hooks/useIsTablet";
import useIsMobile from "hooks/useIsMobile";
import Wallet from "assets/wallet.png";
import SetupProcess from "components/SetupProcess";
import useSetupStore from "store/setupSettingsStore";
import { useAuthStore } from "store/authStore";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

interface ItemType {
  isMobileSidebarOpen: boolean;
  onSidebarClose: (event: React.MouseEvent<HTMLElement>) => void;
  isSidebarOpen: boolean;
}

const Sidebar = ({ isMobileSidebarOpen, onSidebarClose, isSidebarOpen }: ItemType) => {
  const { t } = useTranslation();
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();
  const isSettingProcess = useSetupStore((state) => state.isSettingProcess);
  const { user } = useAuthStore();
  const navigate = useNavigate();
  // useEffect(() => {
  //   console.log("Is Mobile:", isTablet);
  // }, [isTablet]);

  return (
    <>
      {!isTablet ? (
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Drawer
            anchor="left"
            open={isSidebarOpen}
            onClose={onSidebarClose}
            variant="permanent"
            PaperProps={{
              sx: {
                width: "auto",
                minWidth: "270px",
                boxSizing: "border-box",
                backgroundColor: "transparent",
                // marginTop: "70px",
                position: "relative",
                zIndex: 2,
              },
            }}
          >
            <Box sx={{ height: "100%" }}>
              <Box
                sx={{
                  marginTop: "30px",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                }}
              >
                <SidebarItems />
                {isSettingProcess && (
                  <Box sx={{ paddingTop: "50px" }}>
                    <SetupProcess />
                  </Box>
                )}
              </Box>
            </Box>
            <Button
              sx={{
                color: "#fff",
                textTransform: "uppercase",
                backgroundColor: "#F4701B",
                marginBottom: "10vh",
                width: "90%",
                marginLeft: "auto",
                marginRight: "auto",

                "&:hover": {
                  backgroundColor: "#F0670B",
                },
              }}
              className="create_btn"
              variant="contained"
              aria-label="create pwa"
              size="large"
              onClick={() => navigate("/pwa/create")}
            >
              {t("createpwa")}
            </Button>
          </Drawer>
        </Box>
      ) : (
        <Box>
          <Drawer
            anchor="left"
            open={isMobileSidebarOpen}
            onClose={onSidebarClose}
            variant="temporary"
            PaperProps={{
              sx: {
                width: "70vw",
                boxShadow: (theme) => theme.shadows[8],
              },
            }}
          >
            <Box px={2}>{/* LOGO */}</Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "10px",
                paddingBottom: "10px",
                margin: "0 15px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img width={36} height={36} src={IconDuck} alt="logo" />
                <span
                  style={{
                    fontWeight: 400,
                    fontSize: "20px",
                    lineHeight: "24px",
                  }}
                >
                  DuckSay
                </span>
              </div>
              <IconButton onClick={onSidebarClose}>
                <KeyboardBackspaceIcon />
              </IconButton>
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "10px 0px",
              }}
              onClick={() => navigate("/payment")}
            >
              <img width={24} height={24} src={Wallet} alt="wallet" />
              <span
                style={{
                  fontWeight: 600,
                  fontSize: "18px",
                  lineHeight: "20px",
                  color: "#F4701B",
                  marginRight: "10px",
                }}
              >
                {user && user.wallet.toFixed(2)}
              </span>
            </Box>

            <SidebarItems />
            {isSettingProcess && (
              <Box sx={{ paddingTop: "50px" }}>
                <SetupProcess />
              </Box>
            )}
            <Button
              sx={{
                color: "#fff",
                textTransform: "uppercase",
                backgroundColor: "#F4701B",
                marginBottom: "10vh",
                width: "90%",
                marginLeft: "auto",
                marginRight: "auto",

                "&:hover": {
                  backgroundColor: "#F0670B",
                },
              }}
              className="create_btn"
              variant="contained"
              aria-label="create pwa"
              size="large"
              onClick={() => navigate("/pwa/create")}
            >
              {t("createpwa")}
            </Button>
          </Drawer>
        </Box>
      )}
    </>
  );
};

export default Sidebar;
