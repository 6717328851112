function GooglePlayLogo() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.406 11.5527L3.07446 20.3425C3.16802 20.6764 3.33614 20.9844 3.56599 21.2428C3.79584 21.5012 4.08133 21.7033 4.40062 21.8336C4.71992 21.9639 5.06458 22.019 5.40825 21.9946C5.75192 21.9702 6.0855 21.867 6.38349 21.6929L15.758 16.3165L11.406 11.5527Z"
        fill="#EA4335"
      />
      <path
        d="M19.8308 10.0578L15.7767 7.71973L11.2136 11.752L15.7954 16.3032L19.8183 13.9901C20.1748 13.8022 20.4733 13.5196 20.6816 13.1731C20.8899 12.8265 21 12.4291 21 12.024C21 11.6189 20.8899 11.2215 20.6816 10.8749C20.4733 10.5283 20.1748 10.2457 19.8183 10.0578H19.8308Z"
        fill="#FBBC04"
      />
      <path
        d="M3.07452 3.66333C3.02416 3.85081 2.9991 4.04427 3.00002 4.23848V19.7675C3.00053 19.9617 3.02556 20.1549 3.07452 20.3427L11.6917 11.778L3.07452 3.66333Z"
        fill="#4285F4"
      />
      <path
        d="M11.4681 12.0026L15.7767 7.72025L6.41454 2.31884C6.06233 2.11112 5.66175 2.00111 5.25358 2C4.76063 1.99902 4.28088 2.16038 3.88746 2.45947C3.49404 2.75857 3.20853 3.17901 3.07446 3.65669L11.4681 12.0026Z"
        fill="#34A853"
      />
    </svg>
  );
}

export default GooglePlayLogo;
